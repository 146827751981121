@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

/**************************[ BASE ]**************************/
body {
	font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	color: #333;
	background-color: #fff;
}

a, body, i, p, span, u {
	font-size: 16px;
	line-height: 24px;
}
h1 {
	font-size:24px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
	font-weight:bold;
	color:#f59331;
}
h2 {
	font-size:16px;
	font-weight:bold;
	letter-spacing:0;
	text-transform: none !important;
	line-height:20px;
	font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
}
h3 {
	font-size:16px;
	font-weight:bold;
	line-height:inherit;
	font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
}
h4 {
	font-weight:bold;
	font-size:16px;
	font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
}
h6 {
	font-size:24px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
}
.wrapper {
	border-top:none;
}

#opc-login h3, #subscribe-form label, .accordion .heading, .block .block-title, .box-account .box-head h2, .caption .heading, .feature .heading, .footer .heading, .gen-tabs .tabs a, .main-font, .mobnav-trigger, .nav-mobile li.level0>a, .nav-mobile li.level1>a, .nav-mobile li.level2>a, .nav-regular .nav-submenu--mega>li>a, .nav-regular li.level0>a, .order-items .order-comments h2, .order-items h2.sub-title, .order-items h2.table-caption, .product-view .box-reviews dt .heading, .products-grid .product-name, .products-list .product-name, .section-title, body, h1, h2, h3, h3.product-name, h4, h5
{
	font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif !important;
}

.gen-tabs .tabs a.current {
	color:#f59331;
}

/**************************[ HEADER ]**************************/
.header a, .header p, .header span {
	font-size: 14px;
	color: #156345;
	font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
}
.header .dropdown, .header-top {
	line-height: normal;
}

/* Top bar */
.header-container {
	background-color: #fff;
	padding-bottom: 0;
}

.header-top-container .header-top {
	line-height: normal;
}
.welcome-msg *, .userCode, .userName {
	color: #156345 !important;
}

/* Search bar */
.search-wrapper-centered .form-search {
	position: relative;
}
.search-wrapper-centered .form-search .input-text {
	width: 100%;
	background-color: #fff;
	border-color: #999;
	color: #777;
	font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
	display: block;
}
.search-wrapper-centered .form-search .button-search {
	position: absolute;
	top: 0;
	right: 0;
}
.search-wrapper-centered .form-search .button-search .fa {
	font-size: 16px;
	color: #777;
	padding: 10px;
}
.search-wrapper-centered .form-search-globalfilter {
	position: relative;
}
.search-wrapper-centered .form-search-globalfilter label[for="ignore-filter-ignore"] {
	font-size: 12px;
}

/* Cart Widget */
.user-menu, .user-menu a {
	color: #fff;
}
.dropdown-toggle *, .header span.cart-total, .header span {
	color: #f59331;
}
.dropdown-toggle .value {
	color: #fff;
}
.dropdown.open .dropdown-toggle .value {
	color: #f59331;
}
.header .open > .dropdown-toggle.cover > div {
	background-color: transparent;
}
.feature-icon-hover .caret {
	padding-top: 10px;
	font-weight: 700;
	border-top-color: #fff;
	border-width: 6px 5px 0;
}
.dropdown-toggle .icon.close-to-text {
	display: none;
}
.dropdown-toggle .hide-below-1280,
.dropdown-toggle .amount {
	padding: 0.5em 5px;
	font-size: 14px;
}
.empty span.price {
	padding-right: 0.5em;
}
.header .fa.fa-shopping-cart {
	position: relative;
	left: -15px;
	font-size: 18px;
}
#root-wrapper span.fa {
	font-size: 18px;
}
.header-mail-column i.fa.fa-envelope-o {
	font-size: 18px;
	line-height: 35px;
}
.xm-grid-header > .container, .desktop-nav-container ul#nav {
	max-width: none;
	width: 100%;
	margin: 0;
}

.desktop-top-header-container.container.col-main, .desktop-main-header-container.container.col-main {
	margin: 0 auto;
	display: block;
	float: none;
}
.desktop-top-header-container p {
	margin: 0;
}
.desktop-top-header-container * {
	line-height: 35px;
}
.header-contact-column p {
	font-size: 18px;
	font-weight: 900;
	color: #156345;
	line-height: 35px;
}
.header-contact-column p img {
	max-width: 18px;
	vertical-align: middle;
}
.desktop-top-header-container .fax-contact-column, .desktop-top-header-container .phone-contact-column, .desktop-top-header-container .header-mail-column {
	width: auto;
}
.desktop-main-header-container .user-link-column, .desktop-main-header-container .search-column {
	margin-top: 21px;

}
/* Menu */
.desktop-nav-container ul#nav {
	display: flex;
	justify-content: center;
}
.nav-item.nav-item--home.level0 .fa.fa-home::before {
	content: "Home";
	font-family: 'Open Sans', sans-serif;
	font-weight: bold;
	font-size: 16px;
}
.custom-freeshipping-note a, .links > li > a, .nav-regular li.level0 > a > span {
	color: #fff;
	font-size: 16px;
	font-weight: bold;
	text-transform: uppercase;
	font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
}
.user-link-column .header-top.header, .user-link-column .header-top.header .module-header-multi {
	margin: 0;
	width: 100%;
}
.user-link-column .header-top.header .item-user-links {
	margin: 0;
}
.links > li > a {
	background-color: #f59331;
	color: #fff;
	margin-left: 15px;
	font-size: 14px;
	line-height: 36px;
}
.links > li > a:hover {
	color: #87c540 !important;
}
#nav {
	margin-top: 0;
	margin-bottom: 0;
}
#nav, .mobnav-trigger, .desktop-nav-container {
	background-color: #156345;
	border-bottom: none;
}
.nav-regular .nav-item.level0:hover > a,
.nav-mobile .nav-item.level0 > a:hover {
	background-color: #87c540;
	color: #fff;
}
#nav .nav-panel--dropdown a {
	color: #fff;
	font-size: 14px;
	padding: 15px 20px;
	font-weight: bold;
}
#nav .nav-panel--dropdown a > span {
	font-size: 14px;
}
#nav .nav-panel--dropdown a:hover {
	color: #fff;
	background-color: #87c540;
}
.mobnav-trigger.active {
	background-color: #fff;
}
.cms-index-index .nav-regular .nav-item--home > a, .nav-mobile .nav-item.level0.current > a {
	background-color: transparent;
	color: #fff;
}
.nav-regular .nav-item.level0.active > a, .nav-regular .nav-item.level0.active > a:hover {
	background-color: #f59331;
	color: #fff;
}
#nav .nav-item--home .fa-home {
	color: #fff !important;
	padding: 0.3rem;
}
#root-wrapper a:hover span.fa:before {
	color: #fff;
}
#mini-cart .actions button {
	width: 100%;
}
#mini-cart .actions button.button span {
	background-color: transparent !important;
	width: 100%;
}
#mini-cart .actions button.button span span {
	background-color: #f59331 !important;
	padding: 0;
	border-radius: 0;
	max-height: 45px;
	font-size: 16px;
	margin-top: 10px;
}
#mini-cart .actions button.button span span:hover {
	background-color: #87c540 !important;
}
.dropdown-menu > li * {
	float: none;
	display: inline-block;
}
.dropdown-menu > li > a {
	display: inline-block;
}
.mobile-header-container {
	border-bottom: 1px solid #156345;
}
.mobile-header-container .header .logo {
	max-width: 150px;
}
.mobile-header-container .mail-icon-column, .mobile-header-container .user-link-column, .mobile-header-container .header-contact-column {
	width: auto !important;
	float: none;
	vertical-align: middle;
}
.mobile-header-container .mail-icon-column p {
	margin: 0;
}
.mobile-header-container .mail-icon-column i.fa.fa-envelope-o {
	font-size: 32px;
	line-height: 48px;
}
#root-wrapper .mobile-header-container span.fa {
	font-size: 20px;
	padding: .7em;
}
.mobile-nav-container {
	background-color: #156345;
}
.mobile-nav-container .nav.container, .mobile-nav-container .nav.container .mobnav-trigger-wrapper {
	max-width: none;
	width: 100%;
	margin: 0;
}
.mobile-header-container #mini-cart .hide-below-960 {
	display: inline-block !important;
}
.mobile-header-container #mini-cart .empty, .caret {
	display: none;
}
.mobile-header-container #mini-cart .fa.fa-shopping-cart {
	top: 12px;
	left: 0;
}
.mobnav-trigger, .mobnav-trigger.active, .mobnav-trigger:hover {
	color: #fff;
	width: 186px;
	margin: auto;
	background-color: #222;
}
.mobnav-trigger .trigger-icon .line {
	background-color: #fff;
}
#nav.nav-mobile {
	width: 186px !important;
	margin: 0 auto;
	float: none;
}
#nav.nav-mobile .nav-item.level0 {
	font-weight: bold;
}
#nav.nav-mobile .nav-item.level0.active > a {
	background-color: #f59331;
}
#nav.nav-mobile .nav-item.level0 > a > span{
	font-size: 14px;
}
#nav.nav-mobile .nav-item.nav-item--home .feature {
	margin: 0;
}
#nav.nav-mobile .nav-item.nav-item--home span.fa.fa-home {
	line-height: normal;
	padding: 0;
}
#nav.nav-mobile .nav-item.nav-item--home span.fa.fa-home::before {
	font-size: 14px;
}
.nav-search.fly-out {
    display: none;
}

.nav-search .form-search .button-close {
    display: none;
}
.nav-search.fly-out {
    position: fixed;
    display: block !important;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 9999;
    width: 100% !important;
    background-color: #fff;
}
.nav-search.fly-out .form-search {
    position: relative;
    padding: 10px;
}
.nav-search.fly-out .form-search .input-text {
    width: 100%;
    max-width: 100%;
    display: block;
    border: none;
    padding-right: 70px;
}
.nav-search.fly-out .form-search .button {
    top: 20px;
    right: 30px;
	position: absolute;
}
.nav-search.fly-out .form-search .button-search {
    right: 75px;
}
.nav-search.fly-out #inline-search-results {
    top: 50px;
}
.nav-search.fly-out .form-search .button-close {
    display: block;
}
.mobile-header-container .form-search input {
	display: none;
}
.mobile-header-container .form-search .button-search {
	top: 14px;
	right: 10px;
}
@media only screen and (max-width: 959px) {
	.mobile-header-container .mail-icon-column, .mobile-header-container .user-link-column, .mobile-header-container .header-contact-column {
		display: inline-block;
	}
	.user-links-container {
		margin-left: auto !important;
		margin-right: auto !important;
		margin-top: 15px;
		margin-bottom: 15px;
		float: none;
		display: block;
	}
	.user-links-container .nested-container, .mobile-nav-container .nested-container, .mobile-header-container .nested-container {
		margin: 0;
		width: 100%;
	}
	.mobile-header-container.header-contact-container {
		margin-left: auto !important;
		margin-right: auto !important;
		float: none;
		display: block;
	}
}
@media only screen and (min-width: 960px) and (max-width: 1279px) {
	.header-contact-column p, .header a, .header p, .header span {
		font-size: 12px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 959px) {
	.user-links-container {
		width: 50% !important;
	}
	.mobile-header-container.header-contact-container {
		width: 55% !important;
	}
}
@media only screen and (max-width: 767px) {
	.xm-grid-header > .container {
		padding: 0;
	}
	
	.user-links-container {
		padding: 0;
	}
	.mobile-header-container .header-contact-column {
		display: block !important;
	}
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
	.mobile-header-container {
		padding: 0 2%;
	}
}
@media only screen and (max-width: 479px) {
	.mobile-header-container {
		padding: 0 3%;
	}
}
/**************************[ BODY ]**************************/
i.fa {
	font-style: normal;
	color: #156345;
}
.breadcrumbs a, .breadcrumbs li {
	font-size: 14px;
}
.breadcrumbs li span {
	margin: 10px 4px 0;
	font-size: 11px;
	letter-spacing: 2px;
	text-transform: uppercase;
}
.breadcrumbs li span.breadcrumb-separator {
	background: url(../images/img/pix.png) 0 -129px no-repeat;
	width: 7px;
	height: 5px;
	display: block;
	float: left;
	text-indent: -9999px;
	overflow: hidden;
	margin: 12px 4px 0;
}
h1, h1.banner-h1, .banner-h1 a {
	font-size: 32px;
	color: #f59331;
	letter-spacing: 3px;
	text-transform: uppercase;
}
.pager .amount, .sorter .amount {
	line-height: 36px;
}
.toolbar label, .toolbar p, .toolbar span {
	font-size: 14px;
}
.products-grid.single-line-name .product-name {
	overflow: visible;
	text-overflow: initial;
	word-wrap: break-word;
	white-space: normal;
}
.products-grid.centered .item {
	font-size: 14px;
	line-height: 20px;
}
.products-grid .product-name, h3.product-name a {
	font-size: 16px;
}
.bootstrap-styles h2 {
	letter-spacing: 0;
}
.product-view .btn-cart span, button.button span {
	color: #fff;
}
.add-cart-wrapper button span span {
	width: 140px;
	max-width: 140px;
}
.product-view .btn-cart span, button.button span {
	margin: 0;
	background-color: #f59331;
	border-radius: 0;
	padding: 0;
}
button, .button, .button a, button a {
	font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
	font-size: 16px;
}
button.button span {
	font-size: 16px;
}
button.button span span {
	padding: 0 24px;
}
.add-cart-wrapper button:last-child {
	margin-top: 10px;
}
.btn-filled:hover, .button:hover span {
	background-color: #777 !important;
}
.products-grid.centered .item {
	line-height: 20px;
}
.item .price-box .price, .item .price-box .price span, .xm-owl-carousel .price-box .price, .xm-owl-carousel .price-box .price span {
	font-size: 14px;
	color: #f59331;
}
.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
	color: #f59331;
}
.main .col-main, .main .sidebar {
	margin-top: 1%;
	margin-bottom: 1%;
}
.account-login {
	margin: 15px 0 0 0;
	background-color: #fff;
}
.block-title span {
	font-size: 20px;
}
.form-list label {
	font-weight: unset;
}
div[class^='account'] p, div[class^='account'] h2 {
	text-align: left;
}

div[class^='account'] .form-list input.input-text {
	float: left;
}
.category-title, .my-account .page-title {
	border-bottom: none;
}
.pager .pages li a {
	background-color: #f5f5f5;
}
.pager .pages li.current,
.pager .pages li a {
	width: 30px;
	height: 30px;
	line-height: 30px;
}
@media only screen and (min-width: 960px) {
	.show-below-960, .hide-above-960 {
		display: none !important;
	}
}
@media only screen and (max-width: 959px) {
	.hide-below-960 {
		display: none !important;
	}
}
/**************************[ Footer ]**************************/
.footer-container {
	background-color: #ddd;
}
.footer-container, .footer-container2, .footer-primary.footer.container, .footer-top
{
	background-color: #ebebeb;
}
.footer-top-container {
	padding-top: 10px;
}
.footer-primary-container {
	background-color: transparent;
	padding-top: 10px;
	padding-bottom: 10px;
}
.footer-bottom-container.section-container {
	background-color: #333;
	color: #999;
}


/************************** MICS **************************/


